import React from "react";

import Sobha from "../../src/assets/images/Sobha.png"; // Path to your image file
import LNT from "../../src/assets/images/lnt.png"; // Path to your image file
import Purvankara from "../../src/assets/images/Purvankara.png"; // Path to your image file
import Sattva from "../../src/assets/images/sattva.png"; // Path to your image file
import Godrej from "../../src/assets/images/godrej.png"; // Path to your image file
import Brigade from "../../src/assets/images/Brigade.png"; // Path to your image file
import Prestige from "../../src/assets/images/Prestige.png"; // Path to your image file

const Builders = () => {
  const showALL = async (type) => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: [],
      property_status: [],

      property_location: [],
      property_locality: [],
      property_builder: [type],
      property_bhks: [],
      status: [],
      property_starting_price: 0,
      property_maximum_price: 15,
      property_name: "",
      is_featured: 0,
      is_infocused: 0,
    }).toString();
    window.open(
      `/${localStorage.getItem("city")}/properties?${queryParams}`,
      "_blank"
    );
  };

  return (
    <div className=" py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2
          style={{  marginBottom: 30 }}
          className="text-center text-2xl md:text-4xl  font-bold leading-none text-gray-900"
        >
          Ultra luxury projects from reputed developers
        </h2>
        <h2 className="text-center text-md md:text-xl  leading-8 text-gray-900">
          Experience the indian architecture and culture
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-7">
          <img
            onClick={() => showALL("SOBHA Ltd.")}
            // className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={Godrej}
            alt="SavvyCal"
            width={120}
            height={120}
          />

          <img
            onClick={() => showALL("Brigade Group")}
            //   className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={Brigade}
            alt="Reform"
            width={120}
            height={120}
          />
          <img
            onClick={() => showALL("Sattva Group")}
            // className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={Sattva}
            alt="Statamic"
            width={120}
            height={120}
          />
          <img
            onClick={() => showALL("SOBHA Ltd.")}
            //className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={Sobha}
            alt="Transistor"
            width={120}
            height={120}
          />
          <img
            onClick={() => showALL("L&T Realty Limited")}
            // className="col-span-2 col-start-2 max-h-12 w-full object-contain")}

            // className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={LNT}
            alt="Statamic"
            width={120}
            height={120}
          />
          <img
            onClick={() => showALL("Prestige Estates Projects Ltd.")}
            //  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={Prestige}
            alt="Tuple"
            width={120}
            height={120}
          />

          <img
            onClick={() => showALL("Puravankara Ltd.")}
            //  className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={Purvankara}
            alt="Statamic"
            width={120}
            height={120}
          />
        </div>
      </div>
    </div>
  );
};
export default Builders;
