import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import "./SlickList.css";
import Luxury from "../../assets/images/luxury_.png";
import ViewAllGradient from "../../assets/images/ViewAll.png";
import ViewAllImage from "../../assets/images/ViewAllImage.png";
import { IMAGE_PATH } from "../../constant";
import assetContext from "../../context/AssetsContext/AssetContext";

const SlickList = () => {
  const context = useContext(assetContext);
  const { assetsfeatured, totalAssetsFeatured, getAssetsFeatured } = context;
  const navigate = useNavigate();

  const [filters, setFilters]= useState({
    page: 1,
    property_type: [],
    property_status: [],
    property_locality: [],
    property_location:
      localStorage.getItem("city") !== null ? [localStorage.getItem("city")] : [],
    property_builder: [],
    property_bhks: [],
    status: [],
    property_starting_price: 0,
    property_maximum_price: 15,
    property_name: "",
    is_featured: 1,
    is_infocused: 0,
  });
  useEffect(() => {
    getAssetsFeatured(filters);
  }, []);

  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 900px)");

    const handleMediaQueryChange = (e) => {
      setShowDiv(e.matches);
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);

    // Add listener for media query changes
    mediaQuery.addListener(handleMediaQueryChange);

    // Clean up listener on component unmount
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const [defaultImage, setDefaultImage] = useState({});

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent:"center",
          alignItems:"center",
          //color: "white",
          backgroundColor: "grey",
          
          borderRadius: 15,
          width: 50,
          height: 40
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          //display: "block",
           display: "flex",
          justifyContent:"start",
          paddingLeft:5,
          alignItems:"center",
          color: "white",
          backgroundColor: "grey",
          
          borderRadius: 15,
          width: 50,
          height: 40
        }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const showALL = async () => {
    const queryParams = new URLSearchParams(filters ).toString();
    navigate(`/${localStorage.getItem("city")}/properties?${queryParams}`);
  };

  const handleErrorImage = (data) => {
    setDefaultImage((prev) => ({
      ...prev,
      [data.target.alt]: data.target.alt,
      linkDefault: Luxury,
    }));
  };

  const HandleClick = (slug) => {
    window.open(`/${localStorage.getItem("city")}/property/${slug}`, "_blank");
    localStorage.setItem("projectData", JSON.stringify(assetsfeatured));
  };

  return (
    <div style={{ maxWidth: "100%",  }} className="py-10 sm:py-10">
      <h2
        style={{  marginTop: 40 }}
        className="text-center text-2xl md:text-4xl  font-bold leading-none text-gray-900"
      >
        Featured Projects
      </h2>
      <h2 className="text-center text-md md:text-xl  leading-8 text-gray-900">
        Exclusive showcase of top projects
      </h2>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {assetsfeatured.length > 0 ? (
          <div className="Appx">
            <Slider {...settings}>
              {assetsfeatured.map((project, index) => (
                <div
                  onClick={() => HandleClick( project.slug)}
                  key={index}
                  className="card"
                >
                  <div className="card-top">
                    <img
                      src={IMAGE_PATH + project.cover_image}
                      alt={project.title}
                      onError={handleErrorImage}
                    />
                  </div>
                  <div className="card-bottom">
                    <h1
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      {project.property_name}
                    </h1>
                    <h1
                      style={{
                        marginTop: 5,
                        fontSize: 15,
                        color: "grey",
                        backgroundColor: "transparent",
                      }}
                    >
                      {project.property_locality}, {project.property_location}
                    </h1>
                    <h3
                      style={{
                        marginTop: 10,
                        color: "white",
                        fontSize: 16,
                        backgroundColor: "transparent",
                      }}
                    >
                      ₹ {project.property_starting_price} - {project.property_maximum_price} Cr
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                        marginBottom: 20,
                        backgroundColor: "transparent",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          fontSize: 15,
                          backgroundColor: "transparent",
                        }}
                      >
                        {project.property_bhks.bhks.length > 0
                          ? project.property_bhks.bhks.map((itemx, index) => (
                              <div key={itemx.name} style={{ marginRight: 3 }}>
                                {itemx.name + ","}
                              </div>
                            ))
                          : null}
                      </div>
                      <h1
                        style={{
                          fontSize: 15,
                          backgroundColor: "transparent",
                        }}
                      >
                        {project.property_size} Acres
                      </h1>
                    </div>
                    <div
                      style={{
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                        alignItems: "end",
                        display: "flex",
                        justifyContent: "end",
                        color: "inherit",
                      }}
                    >
                      Show more
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ) : null}

        {showDiv && (
          <div
            style={{
              backgroundImage: `url(${ViewAllGradient})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              marginTop: 50,
              borderRadius: 10,
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 10,
              width: "99%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <label
              className="leading-none"
              style={{
                color: "black",
                backgroundColor: "transparent",
              }}
            >
              Featured Projects
            </label>
            <img
              style={{
                marginTop: 20,
                marginLeft: 20,
                marginRight: 20,
                backgroundColor: "transparent",
              }}
              src={ViewAllImage}
              alt="Error"
            />
            <label
              style={{
                color: "black",
                marginLeft: "15px",
                marginRight: "15px",
                marginTop: "25px",
                marginBottom: "25px",
                fontSize: 13,
                fontFamily: "fantasy",
                backgroundColor: "transparent",
              }}
            >
              Dive into luxury living with our exclusive projects, offering unparalleled quality and
              innovation.
            </label>
            <button
              onClick={showALL}
              className="px-5 py-2.5 text-white bg-indigo-600 rounded-lg shadow-md focus:shadow-none duration-100 ring-offset-2 ring-indigo-600 focus:ring-2"
            >
              View All {totalAssetsFeatured} projects
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlickList;
